import { FC } from "react";
import { useTranslation } from "react-i18next";

import { PaymentWrapper } from "@/features/payment/ui/wrapper";
import { PaymentMethod, PaymentMethods, TradingAccount } from "@/services/openapi";
import { usePaymentInfo } from "@/state/server/payment";

import { PaymentMethodStep } from "./step";

type Props = {
  account: TradingAccount;
  currentPaymentMethod: PaymentMethod | null;
  paymentMethodsData: PaymentMethods;
  setPaymentMethod: (method: PaymentMethod) => void;
  onNext: () => void;
  showTitle?: boolean;
  onBack?: () => void;
};

const PaymentMethodStepContainer: FC<Props> = ({
  account,
  currentPaymentMethod,
  setPaymentMethod,
  onNext,
  onBack,
  paymentMethodsData,
  showTitle,
}) => {
  const { t } = useTranslation();

  const { data: paymentInfo } = usePaymentInfo();

  return (
    <PaymentWrapper title={showTitle ? t("payments.account") : void 0} onBack={onBack}>
      {paymentInfo ? (
        <PaymentMethodStep
          currentPaymentMethod={currentPaymentMethod}
          next={onNext}
          paymentInfo={paymentInfo}
          paymentMethods={paymentMethodsData.items!.filter(({ isAvailable }) => isAvailable)}
          setPaymentMethod={setPaymentMethod}
          currentAccount={account}
        />
      ) : (
        <PaymentWrapper.Loader />
      )}
    </PaymentWrapper>
  );
};

export { PaymentMethodStepContainer };
