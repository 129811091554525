import { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { NumberFormat } from "@/app/components";
import { AccountSelect } from "@/entities/accounts/select";
import { PaymentWrapper } from "@/features/payment/ui/wrapper";
import { getWithdrawalRoute } from "@/routes/cabinet.routes";
import { TradingAccount } from "@/services/openapi";
import { NewButton } from "@/shared/ui";
import { Text } from "@/shared/ui";

type Props = {
  accounts: TradingAccount[];
  lastAccount: TradingAccount;
};

const WithdrawalPageForm: FC<Props> = ({ accounts, lastAccount }) => {
  const { t } = useTranslation();
  const [accountId, setAccountId] = useState(lastAccount.id!);
  const account = accounts.find(({ id }) => id === accountId);

  return (
    <PaymentWrapper title={t("payments.account")}>
      <AccountSelect accounts={accounts} accountId={accountId} setAccountId={setAccountId} />

      {account && (
        <Text variant="S / Regular" className="mt-2" color="secondary">
          <Trans
            i18nKey="withdrawal.form.available"
            values={{
              symbol: account.currency,
            }}
            components={{
              click: <></>,
              value: <NumberFormat value={account.balance} decimalScale={account.digits} />,
            }}
          />
        </Text>
      )}

      <PaymentWrapper.Footer>
        <NewButton fullWidth asChild>
          <Link to={getWithdrawalRoute(accountId, true)}>{t("button.next")}</Link>
        </NewButton>
      </PaymentWrapper.Footer>
    </PaymentWrapper>
  );
};

export { WithdrawalPageForm as WithdrawalForm };
