import { FC, useCallback } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";

import { useLocalStorage } from "@/hooks/localstorage.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { authRoutes } from "@/routes/auth.routes";
import { toast, ToastIcons } from "@/shared/ui";
import { useResetPasswordMutation } from "@/state/server/auth";

import { AuthFormContainer } from "../auth-form.container";
import { getTwoFactorSearchParams, TwoFactorMode } from "../two-factor/two-factor.helpers";
import { IResetPasswordFormValues, ResetPasswordForm } from "./reset-password.form";
import { ResetPasswordSuccess } from "./reset-password-success";

export const ResetPasswordContainer: FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [_, setLocalStorage] = useLocalStorage(TwoFactorMode.RESET_PASSWORD, {});

  const { mutateAsync: resetPassword, isSuccess } = useResetPasswordMutation({
    showErrorMessage: false,
  });

  const userId = searchParams.get("userId");
  const code = searchParams.get("code");

  const submitReset = useCallback(
    ({ password }: IResetPasswordFormValues) => {
      return resetPassword(
        { passwordResetRequest: { password, code, userId } },
        {
          onError: (error, requestValues) => {
            if (error.codeMessage === "Auth2FaRequired") {
              setLocalStorage(requestValues);
              navigate({
                pathname: authRoutes.twoFactor,
                search: getTwoFactorSearchParams(TwoFactorMode.RESET_PASSWORD),
              });
              return;
            }
            if (error.errorMessage) toast({ text: error.errorMessage, icon: ToastIcons.ERROR });
          },
        },
      );
    },
    [resetPassword, userId, code, navigate],
  );

  if (!userId || !code) {
    return <Navigate to={authRoutes.signIn} replace />;
  }

  return (
    <AuthFormContainer title={isSuccess ? t("common.success") : t("auth.reset-password.title")} backLink>
      {isSuccess ? <ResetPasswordSuccess /> : <ResetPasswordForm onSubmit={submitReset} />}
    </AuthFormContainer>
  );
};
