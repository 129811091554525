import { FC, memo } from "react";
import { components, ControlProps, OptionProps } from "react-select";

import { Badge } from "@/components/badge";
import { BalanceFormat } from "@/components/balance-format";
import { Box } from "@/components/box";
import { Flex } from "@/components/flex";
import { Text } from "@/components/text";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { TradingAccount, TradingAccountType } from "@/services/openapi";
import { styled } from "@/styles";

import { SelectOptionType } from "../../inputs";
import { SelectField, SelectFieldExternalProps } from "../select";

export type AccountSelectDataType = {
  accountType: TradingAccountType;
  login: number;
  balance: number;
  currency: string;
};

export const getAccountOptions = (accounts: TradingAccount[]): SelectOptionType<AccountSelectDataType>[] => {
  return accounts?.map(({ id, currency, login, balance, type }) => ({
    value: id!,
    label: name!,
    data: { accountType: type!, balance: balance!, currency: currency!, login: login!, name: name! },
  }));
};

const ControlChildrenWrapper = styled(Box, {
  ".react-select__value-container": {
    zIndex: "-1",
    mb: "-25px",
  },

  ".react-select__single-value": {
    display: "none",
  },
});

const Control = (props: ControlProps<SelectOptionType<AccountSelectDataType>, false>) => {
  const { t } = useTranslation();

  const { hasValue, selectProps } = props;
  const { data } = (selectProps.value || {}) as any;
  const { accountType, balance, currency, login } = (data || {}) as AccountSelectDataType;
  const { isMobile } = useScreenSize();

  return (
    <components.Control {...props}>
      {hasValue ? (
        <>
          <Flex css={{ alignItems: "center", justifyContent: "space-between", flexGrow: 1 }}>
            <Flex css={{ alignItems: "center", gap: "12px" }}>
              {!isMobile && (
                <>
                  {accountType === TradingAccountType.Demo ? (
                    <Badge color="orange">{t("common.demo")}</Badge>
                  ) : (
                    <Badge color="blue">{t("common.real")}</Badge>
                  )}
                </>
              )}
              <Flex css={{ flexDirection: "column", "@bp1": { flexDirection: "row", gap: "16px" } }}>
                <Text
                  lineHeight="3"
                  family="roboto"
                  css={{
                    fontSize: "14px",
                    "@bp1": { fontSize: "14px" },
                  }}
                >
                  #{login}
                </Text>
              </Flex>
            </Flex>
            <Text lineHeight="3" family="roboto">
              <BalanceFormat styled={false} value={balance} currency={currency} />
            </Text>
          </Flex>
          <ControlChildrenWrapper>{props.children}</ControlChildrenWrapper>
        </>
      ) : (
        props.children
      )}
    </components.Control>
  );
};

const Option = (props: OptionProps<SelectOptionType<AccountSelectDataType>, false>) => {
  const { t } = useTranslation();

  const {
    data: { data },
  } = props;

  const { accountType, balance, currency, login } = data!;
  const { isMobile } = useScreenSize();

  return (
    <components.Option {...props}>
      <Flex css={{ alignItems: "center", justifyContent: "space-between" }}>
        <Flex css={{ alignItems: "center", gap: "12px" }}>
          {!isMobile && (
            <>
              {accountType === TradingAccountType.Demo ? (
                <Badge color="orange" size={{ "@initial": "small", "@bp1": "middle" }}>
                  {t("common.demo")}
                </Badge>
              ) : (
                <Badge color="blue" size={{ "@initial": "small", "@bp1": "middle" }}>
                  {t("common.real")}
                </Badge>
              )}
            </>
          )}
          <Flex css={{ flexDirection: "column", "@bp1": { flexDirection: "row" }, gap: "12px" }}>
            <Text
              lineHeight="3"
              family="roboto"
              css={{
                fontSize: "14px",
                "@bp1": { fontSize: "14px" },
              }}
            >
              #{login}
            </Text>
          </Flex>
        </Flex>
        <Text lineHeight="3" family="roboto">
          <BalanceFormat styled={false} value={balance} currency={currency} />
        </Text>
      </Flex>
    </components.Option>
  );
};

type Props = SelectFieldExternalProps;

export const _AccountSelect: FC<Props> = props => {
  return <SelectField {...props} components={{ Option, Control }} />;
};

export const AccountSelect = memo(_AccountSelect);
