import { cva } from "class-variance-authority";
import { ElementRef, forwardRef } from "react";

import { NumberFormat } from "@/app/components";
import { cn } from "@/shared/styles";
import { Text } from "@/shared/ui";

import { MergedTerminalSymbol } from "../../helpers/symbols";
import { SymbolIcon } from "../../symbols/icon";
import { SymbolPriceChange } from "../../symbols/price-change";

const buttonStyles = cva(
  "group flex h-14 min-w-[176px] shrink-0 items-center gap-2 whitespace-nowrap rounded-[16px] border border-control-border bg-control-bg px-3 transition-colors hover:bg-control-bg-active",
  {
    variants: {
      isActive: {
        true: "bg-control-bg-active",
      },
    },
  },
);

type Props = {
  symbolInfo: MergedTerminalSymbol;
  onSelect: () => void;
  isActive?: boolean;
  onRemove?: () => void;
};

const DesktopChartSymbolButton = forwardRef<ElementRef<"div">, Props>(
  ({ symbolInfo, onRemove, isActive, onSelect }, forwardedRef) => {
    const { symbol, priceBid, digits, priceLast24H } = symbolInfo;

    return (
      <div ref={forwardedRef} onClick={onSelect} className={cn(buttonStyles({ isActive }))} role="button">
        <SymbolIcon size="lg" symbol={symbol!} />
        <div className="flex grow flex-col gap-0.5">
          <div className="flex grow items-center justify-between gap-2">
            <Text variant="S / Regular" color="primary">
              {symbol}
            </Text>
            {onRemove && (
              <button
                className="text-contrast-primary opacity-0 group-hover:opacity-100"
                onClick={e => {
                  e.stopPropagation();
                  onRemove();
                }}
              >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.52827 5.52851C5.78862 5.26816 6.21073 5.26816 6.47108 5.52851L9.99967 9.05711L13.5283 5.52851C13.7886 5.26816 14.2107 5.26816 14.4711 5.52851C14.7314 5.78886 14.7314 6.21097 14.4711 6.47132L10.9425 9.99992L14.4711 13.5285C14.7314 13.7889 14.7314 14.211 14.4711 14.4713C14.2107 14.7317 13.7886 14.7317 13.5283 14.4713L9.99967 10.9427L6.47108 14.4713C6.21073 14.7317 5.78862 14.7317 5.52827 14.4713C5.26792 14.211 5.26792 13.7889 5.52827 13.5285L9.05687 9.99992L5.52827 6.47132C5.26792 6.21097 5.26792 5.78886 5.52827 5.52851Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            )}
          </div>
          <div className="flex items-center gap-1">
            <Text variant="XS / Regular" color="primary">
              <NumberFormat value={priceBid} decimalScale={digits} />
            </Text>
            <SymbolPriceChange bid={priceBid} priceLast24H={priceLast24H} size="sm" />
          </div>
        </div>
      </div>
    );
  },
);

export { DesktopChartSymbolButton };
