/* tslint:disable */
/* eslint-disable */
/**
 * DoTo CRM API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Возможные платформы, с которых пользователь стучится в наш бекенд
 * @export
 * @enum {string}
 */
export enum ClientPlatform {
    None = 'None',
    Web = 'Web',
    Android = 'Android',
    Ios = 'IOS',
    Mobile = 'Mobile'
}



