/* tslint:disable */
/* eslint-disable */
/**
 * DoTo CRM API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AvailabilityStatus } from '../models';
// @ts-ignore
import { CreateTradingAccountRequest } from '../models';
// @ts-ignore
import { CreateTradingAccountResult } from '../models';
// @ts-ignore
import { DecimalContainer } from '../models';
// @ts-ignore
import { ErrorResult } from '../models';
// @ts-ignore
import { GetDefaultResult } from '../models';
// @ts-ignore
import { Int32ItemsContainer } from '../models';
// @ts-ignore
import { IntegerContainer } from '../models';
// @ts-ignore
import { MetaTrader4OrderItemsContainer } from '../models';
// @ts-ignore
import { MetaTrader4OrderSorting } from '../models';
// @ts-ignore
import { MetaTrader5DealItemsContainer } from '../models';
// @ts-ignore
import { MetaTrader5DealSorting } from '../models';
// @ts-ignore
import { MetaTrader5PositionItemsContainer } from '../models';
// @ts-ignore
import { MetaTrader5PositionSorting } from '../models';
// @ts-ignore
import { MetaTraderBalanceOperationItemsContainer } from '../models';
// @ts-ignore
import { StringContainer } from '../models';
// @ts-ignore
import { TradingAccount } from '../models';
// @ts-ignore
import { TradingAccountItemsContainer } from '../models';
// @ts-ignore
import { TradingAccountPwdRequest } from '../models';
// @ts-ignore
import { TradingAccountSorting } from '../models';
// @ts-ignore
import { TradingAccountTradeMode } from '../models';
// @ts-ignore
import { TradingAccountType } from '../models';
// @ts-ignore
import { TradingAccountsTotalBalance } from '../models';
// @ts-ignore
import { TradingServerItemsContainer } from '../models';
// @ts-ignore
import { TradingServerPlatform } from '../models';
// @ts-ignore
import { TradingTerminalServerItemsContainer } from '../models';
/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveAccount: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('archiveAccount', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/archive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change account investor password
         * @param {string} id 
         * @param {TradingAccountPwdRequest} [tradingAccountPwdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountInvestorPassword: async (id: string, tradingAccountPwdRequest?: TradingAccountPwdRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeAccountInvestorPassword', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/password/investor`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tradingAccountPwdRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change account leverage
         * @param {string} id 
         * @param {IntegerContainer} [integerContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountLeverage: async (id: string, integerContainer?: IntegerContainer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeAccountLeverage', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/leverage`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(integerContainer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change account main password
         * @param {string} id 
         * @param {TradingAccountPwdRequest} [tradingAccountPwdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountMainPassword: async (id: string, tradingAccountPwdRequest?: TradingAccountPwdRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeAccountMainPassword', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/password/main`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tradingAccountPwdRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change account name
         * @param {string} id 
         * @param {StringContainer} [stringContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountName: async (id: string, stringContainer?: StringContainer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeAccountName', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/name`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stringContainer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change account trade mode
         * @param {string} id 
         * @param {TradingAccountTradeMode} [tradeMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountTradeMode: async (id: string, tradeMode?: TradingAccountTradeMode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeAccountTradeMode', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/trade-mode`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (tradeMode !== undefined) {
                localVarQueryParameter['tradeMode'] = tradeMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create trading account
         * @param {CreateTradingAccountRequest} [createTradingAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTradingAccount: async (createTradingAccountRequest?: CreateTradingAccountRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/accounts/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTradingAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Endpoint will be removed soon
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountAvailableLeverages: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAccountAvailableLeverages', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/leverage`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Default account (use `last` instead)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultAccount: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/accounts/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Last account
         * @param {AvailabilityStatus} [status] 
         * @param {TradingAccountType} [type] Тип аккаунта торговорго аккаунта (пользователя или торгового сервера)
         * @param {TradingServerPlatform} [platform] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastAccount: async (status?: AvailabilityStatus, type?: TradingAccountType, platform?: TradingServerPlatform, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/accounts/last`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (platform !== undefined) {
                localVarQueryParameter['Platform'] = platform;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get trading account MT4 open orders
         * @param {string} id 
         * @param {string} [openFrom] 
         * @param {string} [openTo] 
         * @param {string} [closeFrom] 
         * @param {string} [closeTo] 
         * @param {string} [symbol] 
         * @param {MetaTrader4OrderSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMT4OpenOrders: async (id: string, openFrom?: string, openTo?: string, closeFrom?: string, closeTo?: string, symbol?: string, sorting?: MetaTrader4OrderSorting, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMT4OpenOrders', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/mt4/orders/open`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (openFrom !== undefined) {
                localVarQueryParameter['OpenFrom'] = (openFrom as any instanceof Date) ?
                    (openFrom as any).toISOString() :
                    openFrom;
            }

            if (openTo !== undefined) {
                localVarQueryParameter['OpenTo'] = (openTo as any instanceof Date) ?
                    (openTo as any).toISOString() :
                    openTo;
            }

            if (closeFrom !== undefined) {
                localVarQueryParameter['CloseFrom'] = (closeFrom as any instanceof Date) ?
                    (closeFrom as any).toISOString() :
                    closeFrom;
            }

            if (closeTo !== undefined) {
                localVarQueryParameter['CloseTo'] = (closeTo as any instanceof Date) ?
                    (closeTo as any).toISOString() :
                    closeTo;
            }

            if (symbol !== undefined) {
                localVarQueryParameter['Symbol'] = symbol;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get trading account MT4 orders
         * @param {string} id 
         * @param {string} [openFrom] 
         * @param {string} [openTo] 
         * @param {string} [closeFrom] 
         * @param {string} [closeTo] 
         * @param {string} [symbol] 
         * @param {MetaTrader4OrderSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMT4Orders: async (id: string, openFrom?: string, openTo?: string, closeFrom?: string, closeTo?: string, symbol?: string, sorting?: MetaTrader4OrderSorting, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMT4Orders', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/mt4/orders`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (openFrom !== undefined) {
                localVarQueryParameter['OpenFrom'] = (openFrom as any instanceof Date) ?
                    (openFrom as any).toISOString() :
                    openFrom;
            }

            if (openTo !== undefined) {
                localVarQueryParameter['OpenTo'] = (openTo as any instanceof Date) ?
                    (openTo as any).toISOString() :
                    openTo;
            }

            if (closeFrom !== undefined) {
                localVarQueryParameter['CloseFrom'] = (closeFrom as any instanceof Date) ?
                    (closeFrom as any).toISOString() :
                    closeFrom;
            }

            if (closeTo !== undefined) {
                localVarQueryParameter['CloseTo'] = (closeTo as any instanceof Date) ?
                    (closeTo as any).toISOString() :
                    closeTo;
            }

            if (symbol !== undefined) {
                localVarQueryParameter['Symbol'] = symbol;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get trading account MT5 deals
         * @param {string} id 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [symbol] 
         * @param {MetaTrader5DealSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMT5Deals: async (id: string, from?: string, to?: string, symbol?: string, sorting?: MetaTrader5DealSorting, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMT5Deals', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/mt5/deals`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (symbol !== undefined) {
                localVarQueryParameter['Symbol'] = symbol;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get trading account MT5 positions
         * @param {string} id 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [symbol] 
         * @param {MetaTrader5PositionSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMT5Positions: async (id: string, from?: string, to?: string, symbol?: string, sorting?: MetaTrader5PositionSorting, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMT5Positions', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/mt5/positions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (symbol !== undefined) {
                localVarQueryParameter['Symbol'] = symbol;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get trading account balance operations
         * @param {string} id 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMTBalanceOperations: async (id: string, dateFrom?: string, dateTo?: string, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMTBalanceOperations', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/operations/balance`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['DateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['DateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get trading servers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/accounts/servers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get terminal trading servers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalServers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/accounts/servers/terminal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get trading account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingAccount: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTradingAccount', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get trading accounts
         * @param {AvailabilityStatus} [status] 
         * @param {TradingAccountSorting} [sorting] 
         * @param {TradingAccountType} [type] Тип аккаунта торговорго аккаунта (пользователя или торгового сервера)
         * @param {TradingServerPlatform} [platform] 
         * @param {boolean} [isTerminalEnabledOnly] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingAccounts: async (status?: AvailabilityStatus, sorting?: TradingAccountSorting, type?: TradingAccountType, platform?: TradingServerPlatform, isTerminalEnabledOnly?: boolean, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (platform !== undefined) {
                localVarQueryParameter['Platform'] = platform;
            }

            if (isTerminalEnabledOnly !== undefined) {
                localVarQueryParameter['IsTerminalEnabledOnly'] = isTerminalEnabledOnly;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get trading accounts total balance in USD
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingAccountsTotalBalance: async (currency?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/accounts/balance/total`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Make demo deposit
         * @param {string} id 
         * @param {DecimalContainer} [decimalContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeDemoDeposit: async (id: string, decimalContainer?: DecimalContainer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('makeDemoDeposit', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/demo/deposit`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(decimalContainer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restore account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreAccount: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('restoreAccount', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/restore`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update account last action
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLastAction: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLastAction', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/last`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Archive account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveAccount(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveAccount(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change account investor password
         * @param {string} id 
         * @param {TradingAccountPwdRequest} [tradingAccountPwdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeAccountInvestorPassword(id: string, tradingAccountPwdRequest?: TradingAccountPwdRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeAccountInvestorPassword(id, tradingAccountPwdRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change account leverage
         * @param {string} id 
         * @param {IntegerContainer} [integerContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeAccountLeverage(id: string, integerContainer?: IntegerContainer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeAccountLeverage(id, integerContainer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change account main password
         * @param {string} id 
         * @param {TradingAccountPwdRequest} [tradingAccountPwdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeAccountMainPassword(id: string, tradingAccountPwdRequest?: TradingAccountPwdRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeAccountMainPassword(id, tradingAccountPwdRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change account name
         * @param {string} id 
         * @param {StringContainer} [stringContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeAccountName(id: string, stringContainer?: StringContainer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeAccountName(id, stringContainer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change account trade mode
         * @param {string} id 
         * @param {TradingAccountTradeMode} [tradeMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeAccountTradeMode(id: string, tradeMode?: TradingAccountTradeMode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeAccountTradeMode(id, tradeMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create trading account
         * @param {CreateTradingAccountRequest} [createTradingAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTradingAccount(createTradingAccountRequest?: CreateTradingAccountRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTradingAccountResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTradingAccount(createTradingAccountRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Endpoint will be removed soon
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountAvailableLeverages(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32ItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountAvailableLeverages(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Default account (use `last` instead)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaultAccount(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDefaultResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultAccount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Last account
         * @param {AvailabilityStatus} [status] 
         * @param {TradingAccountType} [type] Тип аккаунта торговорго аккаунта (пользователя или торгового сервера)
         * @param {TradingServerPlatform} [platform] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastAccount(status?: AvailabilityStatus, type?: TradingAccountType, platform?: TradingServerPlatform, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradingAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastAccount(status, type, platform, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get trading account MT4 open orders
         * @param {string} id 
         * @param {string} [openFrom] 
         * @param {string} [openTo] 
         * @param {string} [closeFrom] 
         * @param {string} [closeTo] 
         * @param {string} [symbol] 
         * @param {MetaTrader4OrderSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMT4OpenOrders(id: string, openFrom?: string, openTo?: string, closeFrom?: string, closeTo?: string, symbol?: string, sorting?: MetaTrader4OrderSorting, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaTrader4OrderItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMT4OpenOrders(id, openFrom, openTo, closeFrom, closeTo, symbol, sorting, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get trading account MT4 orders
         * @param {string} id 
         * @param {string} [openFrom] 
         * @param {string} [openTo] 
         * @param {string} [closeFrom] 
         * @param {string} [closeTo] 
         * @param {string} [symbol] 
         * @param {MetaTrader4OrderSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMT4Orders(id: string, openFrom?: string, openTo?: string, closeFrom?: string, closeTo?: string, symbol?: string, sorting?: MetaTrader4OrderSorting, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaTrader4OrderItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMT4Orders(id, openFrom, openTo, closeFrom, closeTo, symbol, sorting, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get trading account MT5 deals
         * @param {string} id 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [symbol] 
         * @param {MetaTrader5DealSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMT5Deals(id: string, from?: string, to?: string, symbol?: string, sorting?: MetaTrader5DealSorting, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaTrader5DealItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMT5Deals(id, from, to, symbol, sorting, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get trading account MT5 positions
         * @param {string} id 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [symbol] 
         * @param {MetaTrader5PositionSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMT5Positions(id: string, from?: string, to?: string, symbol?: string, sorting?: MetaTrader5PositionSorting, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaTrader5PositionItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMT5Positions(id, from, to, symbol, sorting, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get trading account balance operations
         * @param {string} id 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMTBalanceOperations(id: string, dateFrom?: string, dateTo?: string, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaTraderBalanceOperationItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMTBalanceOperations(id, dateFrom, dateTo, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get trading servers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradingServerItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get terminal trading servers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTerminalServers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradingTerminalServerItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTerminalServers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get trading account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTradingAccount(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradingAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTradingAccount(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get trading accounts
         * @param {AvailabilityStatus} [status] 
         * @param {TradingAccountSorting} [sorting] 
         * @param {TradingAccountType} [type] Тип аккаунта торговорго аккаунта (пользователя или торгового сервера)
         * @param {TradingServerPlatform} [platform] 
         * @param {boolean} [isTerminalEnabledOnly] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTradingAccounts(status?: AvailabilityStatus, sorting?: TradingAccountSorting, type?: TradingAccountType, platform?: TradingServerPlatform, isTerminalEnabledOnly?: boolean, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradingAccountItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTradingAccounts(status, sorting, type, platform, isTerminalEnabledOnly, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get trading accounts total balance in USD
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTradingAccountsTotalBalance(currency?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradingAccountsTotalBalance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTradingAccountsTotalBalance(currency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Make demo deposit
         * @param {string} id 
         * @param {DecimalContainer} [decimalContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeDemoDeposit(id: string, decimalContainer?: DecimalContainer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makeDemoDeposit(id, decimalContainer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Restore account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreAccount(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreAccount(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update account last action
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLastAction(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLastAction(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountsApiFp(configuration)
    return {
        /**
         * 
         * @summary Archive account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveAccount(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.archiveAccount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change account investor password
         * @param {string} id 
         * @param {TradingAccountPwdRequest} [tradingAccountPwdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountInvestorPassword(id: string, tradingAccountPwdRequest?: TradingAccountPwdRequest, options?: any): AxiosPromise<void> {
            return localVarFp.changeAccountInvestorPassword(id, tradingAccountPwdRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change account leverage
         * @param {string} id 
         * @param {IntegerContainer} [integerContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountLeverage(id: string, integerContainer?: IntegerContainer, options?: any): AxiosPromise<void> {
            return localVarFp.changeAccountLeverage(id, integerContainer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change account main password
         * @param {string} id 
         * @param {TradingAccountPwdRequest} [tradingAccountPwdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountMainPassword(id: string, tradingAccountPwdRequest?: TradingAccountPwdRequest, options?: any): AxiosPromise<void> {
            return localVarFp.changeAccountMainPassword(id, tradingAccountPwdRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change account name
         * @param {string} id 
         * @param {StringContainer} [stringContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountName(id: string, stringContainer?: StringContainer, options?: any): AxiosPromise<void> {
            return localVarFp.changeAccountName(id, stringContainer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change account trade mode
         * @param {string} id 
         * @param {TradingAccountTradeMode} [tradeMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountTradeMode(id: string, tradeMode?: TradingAccountTradeMode, options?: any): AxiosPromise<void> {
            return localVarFp.changeAccountTradeMode(id, tradeMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create trading account
         * @param {CreateTradingAccountRequest} [createTradingAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTradingAccount(createTradingAccountRequest?: CreateTradingAccountRequest, options?: any): AxiosPromise<CreateTradingAccountResult> {
            return localVarFp.createTradingAccount(createTradingAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Endpoint will be removed soon
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountAvailableLeverages(id: string, options?: any): AxiosPromise<Int32ItemsContainer> {
            return localVarFp.getAccountAvailableLeverages(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Default account (use `last` instead)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultAccount(options?: any): AxiosPromise<GetDefaultResult> {
            return localVarFp.getDefaultAccount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Last account
         * @param {AvailabilityStatus} [status] 
         * @param {TradingAccountType} [type] Тип аккаунта торговорго аккаунта (пользователя или торгового сервера)
         * @param {TradingServerPlatform} [platform] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastAccount(status?: AvailabilityStatus, type?: TradingAccountType, platform?: TradingServerPlatform, options?: any): AxiosPromise<TradingAccount> {
            return localVarFp.getLastAccount(status, type, platform, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get trading account MT4 open orders
         * @param {string} id 
         * @param {string} [openFrom] 
         * @param {string} [openTo] 
         * @param {string} [closeFrom] 
         * @param {string} [closeTo] 
         * @param {string} [symbol] 
         * @param {MetaTrader4OrderSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMT4OpenOrders(id: string, openFrom?: string, openTo?: string, closeFrom?: string, closeTo?: string, symbol?: string, sorting?: MetaTrader4OrderSorting, skip?: number, take?: number, options?: any): AxiosPromise<MetaTrader4OrderItemsContainer> {
            return localVarFp.getMT4OpenOrders(id, openFrom, openTo, closeFrom, closeTo, symbol, sorting, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get trading account MT4 orders
         * @param {string} id 
         * @param {string} [openFrom] 
         * @param {string} [openTo] 
         * @param {string} [closeFrom] 
         * @param {string} [closeTo] 
         * @param {string} [symbol] 
         * @param {MetaTrader4OrderSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMT4Orders(id: string, openFrom?: string, openTo?: string, closeFrom?: string, closeTo?: string, symbol?: string, sorting?: MetaTrader4OrderSorting, skip?: number, take?: number, options?: any): AxiosPromise<MetaTrader4OrderItemsContainer> {
            return localVarFp.getMT4Orders(id, openFrom, openTo, closeFrom, closeTo, symbol, sorting, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get trading account MT5 deals
         * @param {string} id 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [symbol] 
         * @param {MetaTrader5DealSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMT5Deals(id: string, from?: string, to?: string, symbol?: string, sorting?: MetaTrader5DealSorting, skip?: number, take?: number, options?: any): AxiosPromise<MetaTrader5DealItemsContainer> {
            return localVarFp.getMT5Deals(id, from, to, symbol, sorting, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get trading account MT5 positions
         * @param {string} id 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [symbol] 
         * @param {MetaTrader5PositionSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMT5Positions(id: string, from?: string, to?: string, symbol?: string, sorting?: MetaTrader5PositionSorting, skip?: number, take?: number, options?: any): AxiosPromise<MetaTrader5PositionItemsContainer> {
            return localVarFp.getMT5Positions(id, from, to, symbol, sorting, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get trading account balance operations
         * @param {string} id 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMTBalanceOperations(id: string, dateFrom?: string, dateTo?: string, skip?: number, take?: number, options?: any): AxiosPromise<MetaTraderBalanceOperationItemsContainer> {
            return localVarFp.getMTBalanceOperations(id, dateFrom, dateTo, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get trading servers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServers(options?: any): AxiosPromise<TradingServerItemsContainer> {
            return localVarFp.getServers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get terminal trading servers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalServers(options?: any): AxiosPromise<TradingTerminalServerItemsContainer> {
            return localVarFp.getTerminalServers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get trading account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingAccount(id: string, options?: any): AxiosPromise<TradingAccount> {
            return localVarFp.getTradingAccount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get trading accounts
         * @param {AvailabilityStatus} [status] 
         * @param {TradingAccountSorting} [sorting] 
         * @param {TradingAccountType} [type] Тип аккаунта торговорго аккаунта (пользователя или торгового сервера)
         * @param {TradingServerPlatform} [platform] 
         * @param {boolean} [isTerminalEnabledOnly] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingAccounts(status?: AvailabilityStatus, sorting?: TradingAccountSorting, type?: TradingAccountType, platform?: TradingServerPlatform, isTerminalEnabledOnly?: boolean, skip?: number, take?: number, options?: any): AxiosPromise<TradingAccountItemsContainer> {
            return localVarFp.getTradingAccounts(status, sorting, type, platform, isTerminalEnabledOnly, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get trading accounts total balance in USD
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingAccountsTotalBalance(currency?: string, options?: any): AxiosPromise<TradingAccountsTotalBalance> {
            return localVarFp.getTradingAccountsTotalBalance(currency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Make demo deposit
         * @param {string} id 
         * @param {DecimalContainer} [decimalContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeDemoDeposit(id: string, decimalContainer?: DecimalContainer, options?: any): AxiosPromise<void> {
            return localVarFp.makeDemoDeposit(id, decimalContainer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Restore account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreAccount(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.restoreAccount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update account last action
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLastAction(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateLastAction(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for archiveAccount operation in AccountsApi.
 * @export
 * @interface AccountsApiArchiveAccountRequest
 */
export interface AccountsApiArchiveAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiArchiveAccount
     */
    readonly id: string
}

/**
 * Request parameters for changeAccountInvestorPassword operation in AccountsApi.
 * @export
 * @interface AccountsApiChangeAccountInvestorPasswordRequest
 */
export interface AccountsApiChangeAccountInvestorPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiChangeAccountInvestorPassword
     */
    readonly id: string

    /**
     * 
     * @type {TradingAccountPwdRequest}
     * @memberof AccountsApiChangeAccountInvestorPassword
     */
    readonly tradingAccountPwdRequest?: TradingAccountPwdRequest
}

/**
 * Request parameters for changeAccountLeverage operation in AccountsApi.
 * @export
 * @interface AccountsApiChangeAccountLeverageRequest
 */
export interface AccountsApiChangeAccountLeverageRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiChangeAccountLeverage
     */
    readonly id: string

    /**
     * 
     * @type {IntegerContainer}
     * @memberof AccountsApiChangeAccountLeverage
     */
    readonly integerContainer?: IntegerContainer
}

/**
 * Request parameters for changeAccountMainPassword operation in AccountsApi.
 * @export
 * @interface AccountsApiChangeAccountMainPasswordRequest
 */
export interface AccountsApiChangeAccountMainPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiChangeAccountMainPassword
     */
    readonly id: string

    /**
     * 
     * @type {TradingAccountPwdRequest}
     * @memberof AccountsApiChangeAccountMainPassword
     */
    readonly tradingAccountPwdRequest?: TradingAccountPwdRequest
}

/**
 * Request parameters for changeAccountName operation in AccountsApi.
 * @export
 * @interface AccountsApiChangeAccountNameRequest
 */
export interface AccountsApiChangeAccountNameRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiChangeAccountName
     */
    readonly id: string

    /**
     * 
     * @type {StringContainer}
     * @memberof AccountsApiChangeAccountName
     */
    readonly stringContainer?: StringContainer
}

/**
 * Request parameters for changeAccountTradeMode operation in AccountsApi.
 * @export
 * @interface AccountsApiChangeAccountTradeModeRequest
 */
export interface AccountsApiChangeAccountTradeModeRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiChangeAccountTradeMode
     */
    readonly id: string

    /**
     * 
     * @type {TradingAccountTradeMode}
     * @memberof AccountsApiChangeAccountTradeMode
     */
    readonly tradeMode?: TradingAccountTradeMode
}

/**
 * Request parameters for createTradingAccount operation in AccountsApi.
 * @export
 * @interface AccountsApiCreateTradingAccountRequest
 */
export interface AccountsApiCreateTradingAccountRequest {
    /**
     * 
     * @type {CreateTradingAccountRequest}
     * @memberof AccountsApiCreateTradingAccount
     */
    readonly createTradingAccountRequest?: CreateTradingAccountRequest
}

/**
 * Request parameters for getAccountAvailableLeverages operation in AccountsApi.
 * @export
 * @interface AccountsApiGetAccountAvailableLeveragesRequest
 */
export interface AccountsApiGetAccountAvailableLeveragesRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetAccountAvailableLeverages
     */
    readonly id: string
}

/**
 * Request parameters for getLastAccount operation in AccountsApi.
 * @export
 * @interface AccountsApiGetLastAccountRequest
 */
export interface AccountsApiGetLastAccountRequest {
    /**
     * 
     * @type {AvailabilityStatus}
     * @memberof AccountsApiGetLastAccount
     */
    readonly status?: AvailabilityStatus

    /**
     * Тип аккаунта торговорго аккаунта (пользователя или торгового сервера)
     * @type {TradingAccountType}
     * @memberof AccountsApiGetLastAccount
     */
    readonly type?: TradingAccountType

    /**
     * 
     * @type {TradingServerPlatform}
     * @memberof AccountsApiGetLastAccount
     */
    readonly platform?: TradingServerPlatform
}

/**
 * Request parameters for getMT4OpenOrders operation in AccountsApi.
 * @export
 * @interface AccountsApiGetMT4OpenOrdersRequest
 */
export interface AccountsApiGetMT4OpenOrdersRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT4OpenOrders
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT4OpenOrders
     */
    readonly openFrom?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT4OpenOrders
     */
    readonly openTo?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT4OpenOrders
     */
    readonly closeFrom?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT4OpenOrders
     */
    readonly closeTo?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT4OpenOrders
     */
    readonly symbol?: string

    /**
     * 
     * @type {MetaTrader4OrderSorting}
     * @memberof AccountsApiGetMT4OpenOrders
     */
    readonly sorting?: MetaTrader4OrderSorting

    /**
     * 
     * @type {number}
     * @memberof AccountsApiGetMT4OpenOrders
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AccountsApiGetMT4OpenOrders
     */
    readonly take?: number
}

/**
 * Request parameters for getMT4Orders operation in AccountsApi.
 * @export
 * @interface AccountsApiGetMT4OrdersRequest
 */
export interface AccountsApiGetMT4OrdersRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT4Orders
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT4Orders
     */
    readonly openFrom?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT4Orders
     */
    readonly openTo?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT4Orders
     */
    readonly closeFrom?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT4Orders
     */
    readonly closeTo?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT4Orders
     */
    readonly symbol?: string

    /**
     * 
     * @type {MetaTrader4OrderSorting}
     * @memberof AccountsApiGetMT4Orders
     */
    readonly sorting?: MetaTrader4OrderSorting

    /**
     * 
     * @type {number}
     * @memberof AccountsApiGetMT4Orders
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AccountsApiGetMT4Orders
     */
    readonly take?: number
}

/**
 * Request parameters for getMT5Deals operation in AccountsApi.
 * @export
 * @interface AccountsApiGetMT5DealsRequest
 */
export interface AccountsApiGetMT5DealsRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT5Deals
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT5Deals
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT5Deals
     */
    readonly to?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT5Deals
     */
    readonly symbol?: string

    /**
     * 
     * @type {MetaTrader5DealSorting}
     * @memberof AccountsApiGetMT5Deals
     */
    readonly sorting?: MetaTrader5DealSorting

    /**
     * 
     * @type {number}
     * @memberof AccountsApiGetMT5Deals
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AccountsApiGetMT5Deals
     */
    readonly take?: number
}

/**
 * Request parameters for getMT5Positions operation in AccountsApi.
 * @export
 * @interface AccountsApiGetMT5PositionsRequest
 */
export interface AccountsApiGetMT5PositionsRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT5Positions
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT5Positions
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT5Positions
     */
    readonly to?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT5Positions
     */
    readonly symbol?: string

    /**
     * 
     * @type {MetaTrader5PositionSorting}
     * @memberof AccountsApiGetMT5Positions
     */
    readonly sorting?: MetaTrader5PositionSorting

    /**
     * 
     * @type {number}
     * @memberof AccountsApiGetMT5Positions
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AccountsApiGetMT5Positions
     */
    readonly take?: number
}

/**
 * Request parameters for getMTBalanceOperations operation in AccountsApi.
 * @export
 * @interface AccountsApiGetMTBalanceOperationsRequest
 */
export interface AccountsApiGetMTBalanceOperationsRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMTBalanceOperations
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMTBalanceOperations
     */
    readonly dateFrom?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMTBalanceOperations
     */
    readonly dateTo?: string

    /**
     * 
     * @type {number}
     * @memberof AccountsApiGetMTBalanceOperations
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AccountsApiGetMTBalanceOperations
     */
    readonly take?: number
}

/**
 * Request parameters for getTradingAccount operation in AccountsApi.
 * @export
 * @interface AccountsApiGetTradingAccountRequest
 */
export interface AccountsApiGetTradingAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetTradingAccount
     */
    readonly id: string
}

/**
 * Request parameters for getTradingAccounts operation in AccountsApi.
 * @export
 * @interface AccountsApiGetTradingAccountsRequest
 */
export interface AccountsApiGetTradingAccountsRequest {
    /**
     * 
     * @type {AvailabilityStatus}
     * @memberof AccountsApiGetTradingAccounts
     */
    readonly status?: AvailabilityStatus

    /**
     * 
     * @type {TradingAccountSorting}
     * @memberof AccountsApiGetTradingAccounts
     */
    readonly sorting?: TradingAccountSorting

    /**
     * Тип аккаунта торговорго аккаунта (пользователя или торгового сервера)
     * @type {TradingAccountType}
     * @memberof AccountsApiGetTradingAccounts
     */
    readonly type?: TradingAccountType

    /**
     * 
     * @type {TradingServerPlatform}
     * @memberof AccountsApiGetTradingAccounts
     */
    readonly platform?: TradingServerPlatform

    /**
     * 
     * @type {boolean}
     * @memberof AccountsApiGetTradingAccounts
     */
    readonly isTerminalEnabledOnly?: boolean

    /**
     * 
     * @type {number}
     * @memberof AccountsApiGetTradingAccounts
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AccountsApiGetTradingAccounts
     */
    readonly take?: number
}

/**
 * Request parameters for getTradingAccountsTotalBalance operation in AccountsApi.
 * @export
 * @interface AccountsApiGetTradingAccountsTotalBalanceRequest
 */
export interface AccountsApiGetTradingAccountsTotalBalanceRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetTradingAccountsTotalBalance
     */
    readonly currency?: string
}

/**
 * Request parameters for makeDemoDeposit operation in AccountsApi.
 * @export
 * @interface AccountsApiMakeDemoDepositRequest
 */
export interface AccountsApiMakeDemoDepositRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiMakeDemoDeposit
     */
    readonly id: string

    /**
     * 
     * @type {DecimalContainer}
     * @memberof AccountsApiMakeDemoDeposit
     */
    readonly decimalContainer?: DecimalContainer
}

/**
 * Request parameters for restoreAccount operation in AccountsApi.
 * @export
 * @interface AccountsApiRestoreAccountRequest
 */
export interface AccountsApiRestoreAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiRestoreAccount
     */
    readonly id: string
}

/**
 * Request parameters for updateLastAction operation in AccountsApi.
 * @export
 * @interface AccountsApiUpdateLastActionRequest
 */
export interface AccountsApiUpdateLastActionRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiUpdateLastAction
     */
    readonly id: string
}

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI {
    /**
     * 
     * @summary Archive account
     * @param {AccountsApiArchiveAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public archiveAccount(requestParameters: AccountsApiArchiveAccountRequest, options?: any) {
        return AccountsApiFp(this.configuration).archiveAccount(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change account investor password
     * @param {AccountsApiChangeAccountInvestorPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public changeAccountInvestorPassword(requestParameters: AccountsApiChangeAccountInvestorPasswordRequest, options?: any) {
        return AccountsApiFp(this.configuration).changeAccountInvestorPassword(requestParameters.id, requestParameters.tradingAccountPwdRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change account leverage
     * @param {AccountsApiChangeAccountLeverageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public changeAccountLeverage(requestParameters: AccountsApiChangeAccountLeverageRequest, options?: any) {
        return AccountsApiFp(this.configuration).changeAccountLeverage(requestParameters.id, requestParameters.integerContainer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change account main password
     * @param {AccountsApiChangeAccountMainPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public changeAccountMainPassword(requestParameters: AccountsApiChangeAccountMainPasswordRequest, options?: any) {
        return AccountsApiFp(this.configuration).changeAccountMainPassword(requestParameters.id, requestParameters.tradingAccountPwdRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change account name
     * @param {AccountsApiChangeAccountNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public changeAccountName(requestParameters: AccountsApiChangeAccountNameRequest, options?: any) {
        return AccountsApiFp(this.configuration).changeAccountName(requestParameters.id, requestParameters.stringContainer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change account trade mode
     * @param {AccountsApiChangeAccountTradeModeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public changeAccountTradeMode(requestParameters: AccountsApiChangeAccountTradeModeRequest, options?: any) {
        return AccountsApiFp(this.configuration).changeAccountTradeMode(requestParameters.id, requestParameters.tradeMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create trading account
     * @param {AccountsApiCreateTradingAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public createTradingAccount(requestParameters: AccountsApiCreateTradingAccountRequest = {}, options?: any) {
        return AccountsApiFp(this.configuration).createTradingAccount(requestParameters.createTradingAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Endpoint will be removed soon
     * @param {AccountsApiGetAccountAvailableLeveragesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getAccountAvailableLeverages(requestParameters: AccountsApiGetAccountAvailableLeveragesRequest, options?: any) {
        return AccountsApiFp(this.configuration).getAccountAvailableLeverages(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Default account (use `last` instead)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getDefaultAccount(options?: any) {
        return AccountsApiFp(this.configuration).getDefaultAccount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Last account
     * @param {AccountsApiGetLastAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getLastAccount(requestParameters: AccountsApiGetLastAccountRequest = {}, options?: any) {
        return AccountsApiFp(this.configuration).getLastAccount(requestParameters.status, requestParameters.type, requestParameters.platform, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get trading account MT4 open orders
     * @param {AccountsApiGetMT4OpenOrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getMT4OpenOrders(requestParameters: AccountsApiGetMT4OpenOrdersRequest, options?: any) {
        return AccountsApiFp(this.configuration).getMT4OpenOrders(requestParameters.id, requestParameters.openFrom, requestParameters.openTo, requestParameters.closeFrom, requestParameters.closeTo, requestParameters.symbol, requestParameters.sorting, requestParameters.skip, requestParameters.take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get trading account MT4 orders
     * @param {AccountsApiGetMT4OrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getMT4Orders(requestParameters: AccountsApiGetMT4OrdersRequest, options?: any) {
        return AccountsApiFp(this.configuration).getMT4Orders(requestParameters.id, requestParameters.openFrom, requestParameters.openTo, requestParameters.closeFrom, requestParameters.closeTo, requestParameters.symbol, requestParameters.sorting, requestParameters.skip, requestParameters.take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get trading account MT5 deals
     * @param {AccountsApiGetMT5DealsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getMT5Deals(requestParameters: AccountsApiGetMT5DealsRequest, options?: any) {
        return AccountsApiFp(this.configuration).getMT5Deals(requestParameters.id, requestParameters.from, requestParameters.to, requestParameters.symbol, requestParameters.sorting, requestParameters.skip, requestParameters.take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get trading account MT5 positions
     * @param {AccountsApiGetMT5PositionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getMT5Positions(requestParameters: AccountsApiGetMT5PositionsRequest, options?: any) {
        return AccountsApiFp(this.configuration).getMT5Positions(requestParameters.id, requestParameters.from, requestParameters.to, requestParameters.symbol, requestParameters.sorting, requestParameters.skip, requestParameters.take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get trading account balance operations
     * @param {AccountsApiGetMTBalanceOperationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getMTBalanceOperations(requestParameters: AccountsApiGetMTBalanceOperationsRequest, options?: any) {
        return AccountsApiFp(this.configuration).getMTBalanceOperations(requestParameters.id, requestParameters.dateFrom, requestParameters.dateTo, requestParameters.skip, requestParameters.take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get trading servers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getServers(options?: any) {
        return AccountsApiFp(this.configuration).getServers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get terminal trading servers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getTerminalServers(options?: any) {
        return AccountsApiFp(this.configuration).getTerminalServers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get trading account
     * @param {AccountsApiGetTradingAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getTradingAccount(requestParameters: AccountsApiGetTradingAccountRequest, options?: any) {
        return AccountsApiFp(this.configuration).getTradingAccount(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get trading accounts
     * @param {AccountsApiGetTradingAccountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getTradingAccounts(requestParameters: AccountsApiGetTradingAccountsRequest = {}, options?: any) {
        return AccountsApiFp(this.configuration).getTradingAccounts(requestParameters.status, requestParameters.sorting, requestParameters.type, requestParameters.platform, requestParameters.isTerminalEnabledOnly, requestParameters.skip, requestParameters.take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get trading accounts total balance in USD
     * @param {AccountsApiGetTradingAccountsTotalBalanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getTradingAccountsTotalBalance(requestParameters: AccountsApiGetTradingAccountsTotalBalanceRequest = {}, options?: any) {
        return AccountsApiFp(this.configuration).getTradingAccountsTotalBalance(requestParameters.currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Make demo deposit
     * @param {AccountsApiMakeDemoDepositRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public makeDemoDeposit(requestParameters: AccountsApiMakeDemoDepositRequest, options?: any) {
        return AccountsApiFp(this.configuration).makeDemoDeposit(requestParameters.id, requestParameters.decimalContainer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Restore account
     * @param {AccountsApiRestoreAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public restoreAccount(requestParameters: AccountsApiRestoreAccountRequest, options?: any) {
        return AccountsApiFp(this.configuration).restoreAccount(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update account last action
     * @param {AccountsApiUpdateLastActionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public updateLastAction(requestParameters: AccountsApiUpdateLastActionRequest, options?: any) {
        return AccountsApiFp(this.configuration).updateLastAction(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}
